import React, { useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import Branding from "../UI/Branding/Branding";

import styles from "./LoginForm.module.scss";
import {
  IconButton,
  InputAdornment,
  SxProps,
  Button,
  TextField,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiAccount, mdiLock, mdiEye, mdiEyeOff } from "@mdi/js";

/** Sx props for the login inputs. */
const outlinedInputSx: SxProps = {
  width: "100%",
  marginBottom: "20px",
  fontSize: "14px",
  fontWeight: "bold",
  "&:focus-visible": {
    borderColor: "#000000",
    color: "#000000",
  },
  "&:focused": {
    borderColor: "#000000",
    color: "#000000",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#000000",
  },
};

/** Sx props for the textfield within the input of the login. */
const textFieldInputSx: SxProps = {
  fontWeight: "bold",
  fontSize: "14px",
  transition: "background-color 5000s ease-in-out 0s",
  marginTop: "0px",
};

/** CSS props for the login input label. */
const inputLabelPropsSx: React.CSSProperties = {
  marginLeft: 30,
  fontSize: "14px",
  fontWeight: "bold",
  marginTop: "2px",
  fontFamily: '"Montserrat SemiBold", sans-serif',
};

const confirmationButtonSx: SxProps = {
  width: "100%",
  height: "48px",
  backgroundColor: "#44AC34",
  color: "#FFFFFF",
  fontSize: "18px",
  textTransform: "none",
  fontFamily: '"Montserrat SemiBold", sans-serif',

  "&.Mui-disabled": {
    backgroundColor: "#44ac3480",
    color: "#FFFFFF",
  },

  "&:hover": {
    backgroundColor: "#368A2B",
  },
};

/**
 * Form that allows the user to log into their CDE Infrastructure account and link it to another tp-group account.
 */
const LoginForm = () => {
  // Configure component translation.
  const { t } = useTranslation("loginForm");

  // React ref for the form's username input.
  const userNameInputRef = useRef<HTMLInputElement>();

  // React ref for the form's password input.
  const pwInputRef = useRef<HTMLInputElement>();

  /** Use state for showing and hiding the text in the password input.*/
  const [showPassword, setShowPassword] = useState(false);

  /** Use state for focused UN(=Username) and PW(=Password) inputs. */
  const [focusedUN, setFocusedUN] = useState(false);
  const [focusedPW, setFocusedPW] = useState(false);

  const [usernameValid, setUsernameValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  // State for getting and setting the form's disabled state.
  const [formDisabled, setFormDisabled] = useState(false);

  // State for showing an error message to the user.
  const [error, setError] = useState("");

  /**
   * Sends a request to the backend to link the user's account.
   */
  const sendRequestHandler = useCallback(async () => {
    try {
      setFormDisabled(true);
      setError("");

      const username: string = userNameInputRef.current?.value ?? "";
      const password: string = pwInputRef.current?.value ?? "";

      if (!username.length || !password.length) {
        throw new Error(t("invalidCredentials"));
      }

      const response = await fetch("/api/verify", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          Username: username,
          Password: password,
        }),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      window.location.href = await response.text();
    } catch (error: any) {
      if (error.message.toLowerCase().indexOf("linked") >= 0) {
        window.location.hash = "#error";

        return;
      }

      setError(error.message);
    } finally {
      setFormDisabled(false);
    }
  }, []);

  /**
   * Attempts to communicate with the backend.
   * @param e - Form submit event.
   */
  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    sendRequestHandler();
  };

  /** By clicking the view icon, the password input will be visible. */
  const handleClickShowPassword = () => {
    pwInputRef.current?.blur();

    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  /** Changes the state depending on the auto fill action. */
  const makeAnimationStartHandler = (stateSetter: any) => (e: any) => {
    const autofilled = !!e.target?.matches("*:-webkit-autofill");

    stateSetter(autofilled || !!e.currentTarget?.value);
  };

  const unInputField = () => {
    return (
      <TextField
        sx={outlinedInputSx}
        required
        inputRef={userNameInputRef}
        onChange={(e) => setUsernameValid(Boolean(e.target.value))}
        onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
        slotProps={{
          htmlInput: {
            sx: textFieldInputSx,
            onAnimationStart: makeAnimationStartHandler(setUsernameValid),
          },
          input: {
            sx: {
              fontSize: "14px",
              fontFamily: '"Montserrat SemiBold", sans-serif',
            },
            startAdornment: (
              <InputAdornment sx={{ mr: "8px" }} position="start">
                <Icon
                  path={mdiAccount}
                  style={{
                    height: "18px",
                    width: "18px",
                    color: "rgba(90, 90, 92, 0.5)",
                    ...(usernameValid && {
                      color: "rgba(90, 90, 92, 1)",
                    }),
                  }}
                />
              </InputAdornment>
            ),
          },
          inputLabel: {
            shrink: focusedUN || usernameValid,
            style: {
              ...inputLabelPropsSx,
              ...(focusedUN && {
                marginLeft: 0,
                color: "#000000",
              }),
              ...(usernameValid && {
                marginLeft: 0,
              }),
            },
          },
        }}
        onFocus={() => setFocusedUN(true)}
        onBlur={() => setFocusedUN(false)}
        label={t("placeholderUN")}
      />
    );
  };

  const pwInputField = () => {
    return (
      <TextField
        sx={outlinedInputSx}
        required
        inputRef={pwInputRef}
        onChange={(e) => setPasswordValid(Boolean(e.target.value))}
        onKeyDown={(e) => e.key === "Enter" && handleSubmit(e)}
        type={showPassword ? "text" : "password"}
        slotProps={{
          htmlInput: {
            sx: textFieldInputSx,
            onAnimationStart: makeAnimationStartHandler(setPasswordValid),
          },
          input: {
            sx: {
              fontSize: "14px",
              fontFamily: '"Montserrat SemiBold", sans-serif',
            },
            startAdornment: (
              <InputAdornment sx={{ mr: "8px" }} position="start">
                <Icon
                  path={mdiLock}
                  style={{
                    height: "18px",
                    width: "18px",
                    color: "rgba(90, 90, 92, 0.5)",
                    ...(passwordValid && {
                      color: "rgba(90, 90, 92, 1)",
                    }),
                  }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  <Icon path={showPassword ? mdiEye : mdiEyeOff} size={1} />
                </IconButton>
              </InputAdornment>
            ),
          },
          inputLabel: {
            shrink: focusedPW || passwordValid,
            style: {
              ...inputLabelPropsSx,
              ...(focusedPW && {
                marginLeft: 0,
                color: "#000000",
              }),
              ...(passwordValid && {
                marginLeft: 0,
              }),
            },
          },
        }}
        onFocus={() => setFocusedPW(true)}
        onBlur={() => setFocusedPW(false)}
        label={t("placeholderPW")}
      />
    );
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <fieldset className={styles.fieldset} disabled={formDisabled}>
        <Branding />

        <h1>{t("connect")}</h1>

        <p className={styles.text}>{t("pleaseLogIn")}</p>

        {unInputField()}
        {pwInputField()}

        {error && <p className={styles.error}>{error}</p>}

        <p className={styles.forgotPw}>
          {t("forgotPassword")} <a href="">{t("resetPassword")}</a>
        </p>

        <Button
          variant="contained"
          disabled={!usernameValid || !passwordValid}
          sx={confirmationButtonSx}
          type="submit"
        >
          {t("connect")}
        </Button>
      </fieldset>
    </form>
  );
};

export default LoginForm;
