import React from "react";
import { ReactComponent as CdeLogo } from "./cde_logo.svg";
import { ReactComponent as CdeInfrastructureLabel } from "./cde_infrastructure_label_dark.svg";

import styles from "./Branding.module.scss";
import { Box } from "@mui/material";

const labelStyle: React.CSSProperties = {
  paddingLeft: "8px",
};

/**
 * Purely cosmetic Component that shows the tp logo and CDE Infrastructure wordmark.
 */
const Branding = () => {
  return (
    <Box className={styles.header}>
      <CdeLogo fill={styles.svg} />
      <CdeInfrastructureLabel style={labelStyle} />
    </Box>
  );
};

export default Branding;
