import React from "react";
import { useTranslation } from "react-i18next";

import Branding from "../UI/Branding/Branding";

import { ReactComponent as Success } from "../../assets/success.svg";
import styles from "./Dialog.module.scss";
import { Button, SxProps } from "@mui/material";

declare var atriumUri: string;
declare var eplassUri: string;

const goToCdeInfrastructureBtnSx: SxProps = {
  display: "block",
  margin: "auto",
  marginBottom: "8px",
  marginTop: "24px",
  backgroundColor: "#44AC34",
  color: "#FFFFFF",
  fontSize: "18px",
  textTransform: "none",
  height: "48px",
  fontFamily: '"Montserrat SemiBold", sans-serif',

  "&:hover": {
    backgroundColor: "#368A2B",
  },
};

const goToAtriumBtnSx: SxProps = {
  display: "block",
  margin: "auto",
  color: "#434343",
  textTransform: "none",
  fontSize: "16px",
  fontFamily: '"Montserrat SemiBold", sans-serif',
};

/**
 * Dialog for showing a success message to the user.
 */
const SuccessDialog = () => {
  // Configure component translation.
  const { t } = useTranslation("successDialog");

  const goToAtriumHandler = () => {
    if (atriumUri) {
      window.location.href = atriumUri;
    } else {
      alert("NOT DEFINED");
    }
  };

  const goToEplassHandler = () => {
    if (eplassUri) {
      window.location.href = eplassUri;
    } else {
      alert("NOT DEFINED");
    }
  };

  return (
    <section className={styles.dialog}>
      <Branding />

      <h1 className={styles.header}>{t("title")}</h1>

      <Success className={styles.svg} />

      <p className={styles.message}>{t("message")}</p>

      <Button
        sx={goToCdeInfrastructureBtnSx}
        variant="contained"
        onClick={goToEplassHandler}
      >
        {t("toEplass")}
      </Button>
      <Button sx={goToAtriumBtnSx} onClick={goToAtriumHandler}>
        {t("toAtrium")}
      </Button>
    </section>
  );
};

export default SuccessDialog;
