import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SxProps } from "@mui/material";

const langPickerSx: SxProps = {
  fontFamily: '"Montserrat Regular", sans-serif',
};

const menuItemSx: SxProps = {
  fontFamily: '"Montserrat Regular", sans-serif',
};

/**
 * Dropdown that allows the user to set the application language.
 */
const LangPicker = () => {
  // Configure translation.
  const { t, i18n } = useTranslation("langPicker");

  // State for getting and setting the application language.
  const [lang, setLang] = useState(i18n.language);

  // Generate a list of the avalable languages as <MenuItem> Components.
  const langs: React.Component[] = (i18n.languages as any)
    .sort()
    .map((x: string, i: number) => (
      <MenuItem sx={menuItemSx} value={x} key={i}>
        {t(x)}
      </MenuItem>
    ));

  /**
   * Updates the Application language when the user selects on the of dropdown items.
   * @param event - Change event emitted by the Select Component.
   */
  const handleChange = (event: SelectChangeEvent<string>) => {
    const newLang: string = event.target.value;

    i18n.changeLanguage(newLang);

    setLang(newLang);
  };

  return (
    <Select sx={langPickerSx} value={lang} onChange={handleChange}>
      {langs}
    </Select>
  );
};

export default LangPicker;
