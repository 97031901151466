import React from "react";
import { useTranslation } from "react-i18next";

import Branding from "../UI/Branding/Branding";

import { ReactComponent as Warning } from "../../assets/warning.svg";
import styles from "./Dialog.module.scss";
import { Button, SxProps } from "@mui/material";

declare var atriumUri: string;

const goToAtriumBtnSx: SxProps = {
  display: "block",
  margin: "auto",
  marginBottom: "8px",
  marginTop: "24px",
  backgroundColor: "#44AC34",
  color: "#FFFFFF",
  fontSize: "18px",
  fontFamily: '"Montserrat SemiBold", sans-serif',
  textTransform: "none",
  height: "48px",

  "&:hover": {
    backgroundColor: "#368A2B",
  },
};

/**
 * Dialog for showing an error message to the user.
 */
const ErrorDialog = () => {
  // Configure component translation.
  const { t } = useTranslation("errorDialog");

  const goToAtriumHandler = () => {
    if (atriumUri) {
      window.location.href = atriumUri;
    }
  };

  return (
    <section className={styles.dialog}>
      <Branding />

      <h1 className={styles.header}>{t("title")}</h1>

      <Warning className={styles.svg} />

      <p className={styles.message}>{t("message")}</p>

      <Button
        variant="contained"
        sx={goToAtriumBtnSx}
        onClick={goToAtriumHandler}
      >
        {t("toAtrium")}
      </Button>
    </section>
  );
};

export default ErrorDialog;
