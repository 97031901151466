import React, { useEffect, useState } from 'react';

import { useBreakpoint } from './context/BreakpointContext';
import LoginForm from './components/LoginForm/LoginForm';
import LangPicker from './components/UI/LangPicker/LangPicker';
import SuccessDialog from './components/Dialog/SuccessDialog';
import ErrorDialog from './components/Dialog/ErrorDialog';

import styles from './App.module.scss';
import './Styles.scss';

const App = () =>
{
    // Determine screen size.
    const breakpoints: any = useBreakpoint();

    // State slice for determining the Application's error state.
    const [isError, setIsError] = useState(false);

    // State slice for determining the Application's success state.
    const [isSuccess, setIsSuccess] = useState(false);

    // Effect that handles changes to the URL Hash.
    useEffect(
        () =>
        {
            /**
             * Updates the error and success state slices based on the URL hash.
             */
            const update = () =>
            {
                const path: string = window.location.hash;

                setIsError(path === "#error");
                setIsSuccess(path === "#success");
            }

            window.addEventListener("hashchange", update);

            update();

            return () => window.removeEventListener("hashchange", update);
        },
        [isError, isSuccess],
    );

    return (
        <section className={styles.main}>
            {breakpoints.l && <img className={styles.bg} src={`${process.env.PUBLIC_URL}/assets/images/background.jpg`} />}
            <section className={styles.form}>
                <div className={styles.langPickerWrapper}>
                    <LangPicker />
                </div>

                {!isError && !isSuccess && <LoginForm />}
                {isError && <ErrorDialog />}
                {isSuccess && <SuccessDialog />}
            </section>
        </section>
    );
};

export default App;
